<template>
  <v-row>
    <v-col>
      <h4>Quotations for each sales person this month</h4>
      <ul>
        <li v-for="(stat, index) in salesStats" :key="index">
          <router-link :to="`/reports/chase-report?sp=${stat.user}`">
            {{  stat.user }}: {{ stat.total | priceInPounds | currency }}
          </router-link>
        </li>
      </ul>
    </v-col>
    <v-col>
      <h4>Conversions for each sales person this month</h4>
      <ul>
        <li v-for="(stat, index) in conversionsStats" :key="index">
          {{  stat.user }}: {{ stat.total | priceInPounds | currency }}
        </li>
      </ul>
    </v-col>
    <v-col>
      <h4>Invoices for each sales person this month</h4>
      <ul>
        <li v-for="(stat, index) in invoiceStats" :key="index">
          {{  stat.user }}: {{ stat.total | priceInPounds | currency }}
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DashboardDirectors',
  data() {
    return {
      conversionsStats: [],
      invoiceStats: [],
      salesStats: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    getSalesStatistics() {
      axios.get(`/quotations/getDirectorsSalesStats.json?token=${this.token}`)
        .then((response) => {
          this.salesStats = response.data.salesStats;
        });
    },
    getConversionsStatistics() {
      axios.get(`/quotations/getDirectorsConversionsStats.json?token=${this.token}`)
        .then((response) => {
          this.conversionsStats = response.data.conversionsStats;
        });
    },
    getInvoiceStatistics() {
      axios.get(`/quotations/getDirectorsInvoiceStats.json?token=${this.token}`)
        .then((response) => {
          this.invoiceStats = response.data.salesStats;
        });
    },
  },
  mounted() {
    this.getConversionsStatistics();
    this.getInvoiceStatistics();
    this.getSalesStatistics();
  },
};
</script>
